import { LoopingEstimationResult, estimateLooping } from '../helpers/leverage';
import BigNumber from 'bignumber.js';
import { ComputedReserveData, UserSummary } from '../libs/pool-data-provider';
import { ChefIncentivesHelper, StorePoolInfoModel } from '../store';
import { Address } from 'viem';
import { TokensSymbolEnum } from '../shared';

export interface EstimateLoopingAndAprs extends LoopingEstimationResult {
  rewardsDepositApr: BigNumber;
  rewardsBorrowApr: BigNumber;
}

export class PoolUtils {
  public static getPoolLooping({
    reserve,
    poolsInfoData,
    amount,
    userSummary,
    maxLeverage,
  }: {
    reserve: ComputedReserveData;
    poolsInfoData: StorePoolInfoModel[];
    amount: BigNumber;
    maxLeverage: BigNumber;
    userSummary?: UserSummary;
  }): EstimateLoopingAndAprs {
    const rewardsDeposit = ChefIncentivesHelper.getPoolInfoDataByToken(
      poolsInfoData,
      reserve.aTokenAddress as Address
    );

    const rewardsBorrow = ChefIncentivesHelper.getPoolInfoDataByToken(
      poolsInfoData,
      reserve.variableDebtTokenAddress as Address
    );

    // if (reserve.symbol === TokensSymbolEnum.wIOTA) {
    //   console.log({
    //     rewardsBorrow: rewardsBorrow.apr.toString(),
    //     variableDebtTokenAddress: reserve.variableDebtTokenAddress,
    //   });
    // }

    const loopping = estimateLooping({
      amount: amount,
      asset: reserve,
      leverage: maxLeverage,
      depositIncentiveAPR: rewardsDeposit?.apr,
      variableBorrowIncentiveAPR: rewardsBorrow?.apr,
      userSummary: userSummary,
    });

    loopping.netAPY = loopping.netAPY.abs();

    return {
      rewardsDepositApr: rewardsDeposit?.apr,
      rewardsBorrowApr: rewardsBorrow?.apr,
      ...loopping,
    };
  }
}
