import { valueToBigNumber } from '@aave/math-utils';
import { GraphPoolInfoDataModel } from '../../graphs';
import { DateUtils, Web3Utils } from '../../utils';
import { StorePoolInfoModel } from './chef-incentives.model';
import { Address } from 'viem';
import { BigNumber } from 'ethers';

export class ChefIncentivesHelper {
  public static getArraySum(rewardsRates: number[]): number {
    return rewardsRates.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  public static async comparePoolInfoData(
    data: GraphPoolInfoDataModel[],
    totalSupplies: Record<string, BigNumber>,
    rewardTokenPrice: string
  ): Promise<StorePoolInfoModel[]> {
    return data.map((poolInfoData) => {
      const allocPoint = Number(poolInfoData.allocPoint);
      const totalAlloc = Number(poolInfoData.totalAlloc);
      const rewardsPerSecond = valueToBigNumber(
        Web3Utils.formatValue(poolInfoData.rewardsPerSecond)
      );
      const price = Web3Utils.formatValue(poolInfoData.price, 8);

      const totalSupply = valueToBigNumber(
        Web3Utils.formatValue(
          totalSupplies[`pool-${poolInfoData.tokenAddress}`],
          poolInfoData.decimals
        )
      );

      const totalValueInUsd = totalSupply.times(price);

      const rewardsPerSecondInUsd = valueToBigNumber(rewardsPerSecond).times(rewardTokenPrice);

      const totalRewardsPerYearInUsd = rewardsPerSecondInUsd.times(DateUtils.getYearSeconds());

      const coef = allocPoint / totalAlloc;
      const marketRewardsPerYearInUsd = totalRewardsPerYearInUsd.times(coef);

      let apr = marketRewardsPerYearInUsd.div(totalValueInUsd).times(100);

      return {
        ...poolInfoData,
        price,
        rewardsPerSecond,
        totalSupply,
        totalRewardsPerYearInUsd,
        coef,
        marketRewardsPerYearInUsd,
        apr,
        totalValueInUsd,
      };
    });
  }

  public static getPoolInfoDataByToken(poolInfoData: StorePoolInfoModel[], address: Address) {
    const data = poolInfoData.find(
      (data) => data.tokenAddress.toLowerCase() === address.toLowerCase()
    );

    if (data) {
      return data;
    } else {
      // return poolInfoData[0];
      throw new Error(`Couldn't find projected data per token: ${address}`);
    }
  }
}
